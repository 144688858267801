import React from 'react';


const Test2 = () => {
  return (
    <div className="text-center p-8 rounded-lg mx-[10%] sm:mx-[5%] md:mx-[10%] mt-9">
      <h2 className="text-left text-[16px] sm:text-[18px] md:text-[20px] text-[#949995] mb-4">
        WHAT OUR CLIENTS SAY
      </h2>
      <p className="text-[#5ab963] mb-4 text-[20px] sm:text-[22px] md:text-[25px] mx-[2%] sm:mx-[4%] md:mx-[8%]">
        “It went really well and we enjoyed it. It showed the [property recee] is obviously a must to discover things like the size of house, actual views and unseen damage like the deck structure.
        <br /><br />
        We still think the house is good overall, and the condition is excellent, but the above items will allow as to properly assess the right offer.”
      </p>
      <div className="flex flex-col sm:flex-row justify-center items-center">
        {/* <div className="w-[4rem] h-[4rem] sm:w-[3rem] sm:h-[3rem]">
          <img src={humanTemp} className="rounded-full w-full h-full" alt="Client" />
        </div> */}
        <div>
          <p className="font-[300] text-[14px] sm:text-[16px]">Aily and Warner Lamb</p>
        </div>
      </div>
    </div>
  );
};

export default Test2;