import React from "react";
import cheerio from "cheerio";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
const parseContent = (blogContent) => {
  const $ = cheerio.load(blogContent);
  const content = $.text(); // Get all the text within the HTML content
  return content.split(" ").slice(0, 30).join(" "); // Limit to the first 30 words
};

const BlogCard = ({ item }) => {
  console.log(item);
  const navigate = useNavigate();
  const {
    blog_heading,
    coverImage,
    createdAt,
    blog_content = "",
    serial_number,
  } = item;

  const formattedDate = new Date(createdAt).toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  const content = parseContent(blog_content);

  return (
    <Link
      to={`/blogs/${serial_number}`}
      // target="_blank"
      className="flex shadow-xl w-full md:w-[50%] lg:w-[31%] justify-around cursor-pointer"
    >
      <div className="flex flex-col w-full">
        <div className="h-[18rem] w-full">
          <img
            loading="lazy"
            src={coverImage}
            alt="Event"
            className="w-full h-full object-cover"
          />
        </div>

        <div className="mx-3">
          <p className="mt-2 text-[--medium-sea-green]">{formattedDate}</p>
          <p className="mt-2 font-bold text-xl mb-2">{blog_heading}</p>
          <p className="font-light text-md text-[--dark-grey] mb-10">
            {content}...
          </p>
        </div>
      </div>
    </Link>
  );
};

export default BlogCard;
