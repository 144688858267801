import React, { useState, useEffect } from "react";
import { fetchData } from "../../Utils/CommonFunctions";

import CardSlider from "./NewsCards/customCardSlider";

import Loader from "../Loader/Loader";
import NewsCard from "./NewsCards/NewsCard";


// import {
//   headingData,
//   eventData,
//   sliderImages,
//   headingForAllData,
// } from "./../community/useCommunityHook";

function AkiyaOnNews() {
  const [latestBlogData, setLatestBlogData] = useState([]);
  const [latestNews, setLatestNews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDataAsync = async () => {
      try {
        const blogResponse = await fetchData("https://app.akiya2.com/blogs");
        const newsResponse = await fetchData("https://app.akiya2.com/all_news");

        const sortedBlogData = blogResponse
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(0, 5);

        setLatestBlogData(sortedBlogData);
        setLatestNews(newsResponse);
        setLoading(false); // Set loading to false once data is fetched
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading to false in case of error
      }
    };
    fetchDataAsync();
  }, []);

  return (
    <div>
      {/* <HeadingForAll
          smallHeading=Follow Along with us
          bigHeading="Spotlight Series: Where Akiya2 Shines Today?"
        /> */}

      <div className="bg-[#5ab963] flex justify-center relative ">
        <div className="md:ml-[3rem] ml-[0.5rem] text-[#fff] pb-[2.5rem] w-[100%] max-w-[1440px]">
          <h1 className="m-0 md:text-[24px] text-[12px] font-[800] pt-[0.5rem]">
            Follow Along with us
          </h1>
          <h1 className="text-[72px] font-[800] leading-[78px] pt-[0.5rem] pb-[7rem] second">
            Akiya2.0 in the spotlight
          </h1>
        </div>
      </div>
      
      <div className="w-[100vw] flex justify-center items-center flex-col mt-[-10rem]">
        <div className="max-w-[1440px]">
          <div>
            {/* <h1 className="dark-header mb-0">Akiya2.0 in the news</h1>
          <h1 className="paragraph2 mx-[30px] my-0">
            Discover the buzz around Akiya2: reviving Japan's akiyas. Follow for
            updates on our mission.
          </h1> */}

            {loading ? ( // Show loader while data is being fetched
              <Loader />
            ) : (
              <div className="w-full flex items-center justify-center my-10">
                <div className="w-[95%]">
                  <CardSlider
                    data={latestNews}
                    Card={NewsCard} // Assuming you have a NewsCard component
                    endValues={{ xs: 1, md: 2, lg: 3 }}
                  />
                </div>
              </div>
            )}
            {/* 
          <div className="max-w-[1500px] mx-8">
            <div className="max-w-[940px] mb-6 mr-3 ">
              <Heading data={headingData[1]} />
            </div>
            {latestBlogData.length ? (
              <CardSlider
                data={latestBlogData}
                Card={BlogCard}
                endValues={{
                  xs: 1,
                  md: 2,
                  lg: 3,
                }}
              />
            ) : (
              <Loader big={false} />
            )}
          </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AkiyaOnNews;
