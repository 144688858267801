import React from "react";
import Tables from "./Tables";
import HeadingForAll from "../../HeadingForAll/HeadingForAll";
import DownloadProspectus from "./DownloadProspectus";
import Test2 from "./Test2";
import Form from "../../akiyaForm/Form";
import Heading from "../../headingContainer/Heading";
import { headingData, subHeadingdata } from "../../buyAnAkiya/BuyAnAkiyaHook";


// console.log("Tables:",Tables);
// console.log("HeadingForAll:",HeadingForAll);
// console.log("DownloadProspectus:",DownloadProspectus);
// console.log("Test2:",Test2);
// console.log("AkiyaForm:",Form);


function WhatItCosts() {
  const firstHeading = "Our services - What It Costs";
  const secondHeading = "Your way into rural Japanese real estate";

  const mainHeader =
    "Easy to understand pricing with no hidden fees/motivations ";
  return (
    <div>
      <HeadingForAll smallHeading={firstHeading} bigHeading={secondHeading} />
      <div className="v-[100vw] h-[100%] flex justify-center items-center">
      <div className="w-[100%] max-w-[1440px]">
        <h1 className="dark-header my-[2rem]">{mainHeader}</h1>

        <Tables />
        <DownloadProspectus />
        <Test2 />

        <div className="h-[10vh] w-full"></div>
        <div className="w-full flex items-center justify-center">
          <div className="flex flex-col md:flex-row w-full max-w-[1440px] my-5 gap-12 mx-[3rem]">
            <div className=" md:w-[50%] w-full p-2">
              <Heading data={headingData[1]} />
            </div>
            <div className=" md:w-[50%] w-full">
              <Form />
            </div>
          </div>
        </div>
      </div>

      </div>

    </div>
  );
}

export default WhatItCosts;
