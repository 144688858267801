import React from 'react';


const japanTravel = "/images/about/japantravel.png";
const o2ostays = "/images/about/o2ostays.png";
const suzu = "/images/about/suzu.png";
const wajima = "/images/about/wajima.png";
const kominka = "/images/about/kominka.png";
const kominka1 = "/images/about/kominka1.jpg";
const imabari = "/images/about/imabari.png";

const LogoSlider = () => {
  const logos = [
    { src: japanTravel, link: 'https://en.japantravel.com/' },
    { src: o2ostays, link: 'https://www.o2ostays.com/' },
    { src: suzu, link: 'https://www.city.suzu.lg.jp.e.er.hp.transer.com/' },
    { src: wajima, link: 'https://www.city.wajima.ishikawa.jp/top.html' },
    { src: kominka, link: 'https://kominka.net/' },
    { src: kominka1, link: 'https://kominka.net/' },
    { src: imabari, link: 'https://www.city.imabari.ehime.jp/' },
  ];

  const handleClick = (link) => {
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="relative overflow-hidden bg-white py-4 sm:pb-8 md:pb-12 lg:pb-16 max-w-[100%]">
      <div className="relative flex animate-slide whitespace-nowrap">
        {logos.map((logo, index) => (
          <img
            key={index}
            src={logo.src}
            alt={`Logo ${index + 1}`}
            className="inline-block h-8 mx-4 sm:h-10 sm:mx-6 md:h-12 md:mx-8 lg:h-16 lg:mx-10 cursor-pointer"
            onClick={() => handleClick(logo.link)}
          />
        ))}
        {logos.map((logo, index) => (
          <img
            key={`copy-${index}`}
            src={logo.src}
            alt={`Logo ${index + 1}`}
            className="inline-block h-8 mx-4 sm:h-10 sm:mx-6 md:h-12 md:mx-8 lg:h-16 lg:mx-10 cursor-pointer"
            onClick={() => handleClick(logo.link)}
          />
        ))}
      </div>
      <div className="absolute inset-y-0 left-0 w-48 bg-gradient-to-r from-white to-transparent"></div>
      <div className="absolute inset-y-0 right-0 w-48 bg-gradient-to-l from-white to-transparent"></div>
    </div>
  );
};

export default LogoSlider;
