import React from "react";
import Internshipform from "../Form/InternshipForm";
import Middle from "../Middle/Middle";
import HeadingforSmall from "../../HeadingforSmall/HeadingforSmall";
import Card from "../InfoCards/infocards";
import Boxes from "../Boxes/Boxes";
import InternCard from "../Interncard/interncard";

function Intern() {
  const secondHeading = "Akiya 2.0 Internship Programmes";
  return (
    <div>
      <HeadingforSmall bigHeading={secondHeading} />

      <div className="w-[100vw] flex justify-center">
        <div className=" max-w-[1440px] lg:mx-[3rem]  md:mx-[2rem] mx-[1rem] flex flex-col gap-8  ">
          <Middle />
          <Card />
          <Boxes />
          <InternCard />
          <Internshipform />
        </div>
      </div>
    </div>
  );
}

export default Intern;
