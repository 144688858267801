import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getMetaTags } from '../Utils/metaTagManager';

const MetaTagsUpdater = () => {
  const location = useLocation();
  const [metaTags, setMetaTags] = useState(
    typeof window !== 'undefined' ? window.__INITIAL_META_TAGS__ || {} : {}
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const updateMetaTags = async () => {
        const newMetaTags = await getMetaTags(location.pathname, location.state);
        setMetaTags(newMetaTags);

        document.title = newMetaTags.title || 'Default Title';
        
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) metaDescription.setAttribute('content', newMetaTags.description || '');

        const metaKeywords = document.querySelector('meta[name="keywords"]');
        if (metaKeywords) metaKeywords.setAttribute('content', newMetaTags.keywords || '');

        const ogTitle = document.querySelector('meta[property="og:title"]');
        if (ogTitle) ogTitle.setAttribute('content', newMetaTags.ogTitle || '');

        const ogDescription = document.querySelector('meta[property="og:description"]');
        if (ogDescription) ogDescription.setAttribute('content', newMetaTags.ogDescription || '');

        const ogImage = document.querySelector('meta[property="og:image"]');
        if (ogImage) ogImage.setAttribute('content', newMetaTags.ogImage || '');
      };

      updateMetaTags();
    }
  }, [location]);

  return null;
};

export default MetaTagsUpdater;