// src/Components/BlogWriting/BlogWriting.jsx

import React, { useState, useEffect } from "react";
import axios from "axios";
import { fetchDataWithToken } from "../../Utils/CommonFunctions";
import { FaTimes } from "react-icons/fa";
import HeadingForAll from "../HeadingForAll/HeadingForAll";
import "./styles.css";
import Select from "react-select";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: "#FFFFFF",
    borderColor: state.isFocused ? "#5AB963" : "#5AB963",
    "&:hover": {
      borderColor: state.isFocused ? "#5AB963" : "#5AB963",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? "#5AB963" : "#FFFFFF",
    color: state.isDisabled ? "#FFFFFF" : "#333333",
    cursor: state.isDisabled ? "not-allowed" : "default",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: state.isSelected ? "#5AB963" : "#FFFFFF",
  }),
  // Add more custom styles if needed
};

export default function BlogWriting() {
  const [JoditEditor, setJoditEditor] = useState(null);
  const [editorConfig, setEditorConfig] = useState(null);
  const [author, setAuthor] = useState(null);
  const [greeting, setGreeting] = useState("");
  const [header, setHeader] = useState("");
  const [data, setData] = useState("");
  const [changed, isChanged] = useState(false);
  const [isError, setIsError] = useState(false);
  const [coverImage, setCoverImage] = useState(null);
  const [selectedFruits, setSelectedFruits] = useState([]);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    password: "",
    facebook_link: "",
    twitter_link: "",
    linkedin_link: "",
    about_them: "",
    image: "",
    first_name: "",
    last_name: "",
  });
  const [responseMessage, setResponseMessage] = useState("");

  const firstHeading = greeting + ", " + userData.first_name;
  const secondHeading = "Ready to write something new";

  useEffect(() => {
    const access_token = localStorage.getItem("access_token");

    fetchDataWithToken("https://app.akiya2.com/get-user-data", access_token).then(
      (data) => {
        if (data) {
          setUserData(data);
        } else {
          localStorage.removeItem("access_token");
        }
      }
    );
  }, []);

  useEffect(() => {
    if (changed) {
      const timeoutId = setTimeout(() => {
        window.location.href = "/";
      }, 2000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [changed]);

  useEffect(() => {
    setAuthor(`${userData.first_name} ${userData.last_name}`);
  }, [userData]);

  useEffect(() => {
    function getGreeting() {
      const currentHour = new Date().getHours();
      if (currentHour >= 5 && currentHour < 12) {
        return "Good morning";
      } else if (currentHour >= 12 && currentHour < 18) {
        return "Good afternoon";
      } else {
        return "Good evening";
      }
    }

    setGreeting(getGreeting());
  }, []);

  // Dynamically load JoditEditor and editorConfig only on the client side
  useEffect(() => {
    if (typeof window !== "undefined") {
      // Dynamically import 'jodit-react' and 'config.js'
      Promise.all([
        import("jodit-react"),
        import("./config").then((module) => module.getEditorConfig()),
      ])
        .then(([joditReactModule, config]) => {
          setJoditEditor(joditReactModule.default);
          setEditorConfig(config);
        })
        .catch((error) => {
          console.error("Error loading JoditEditor or config:", error);
        });
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("AuthorName", author || "");
    formData.append("BlogHeading", header || "");
    formData.append("BlogContent", data || "");
    if (coverImage instanceof File) {
      formData.append("CoverImage", coverImage);
    }
    // Append selected fruits to form data if needed
    // selectedFruits.forEach((fruit) => {
    //   formData.append("SelectedFruits[]", fruit.value);
    // });

    try {
      const response = await axios.post(
        "https://app.akiya2.com/addBlog",
        formData
      );

      setResponseMessage(response.data.message);
      setIsError(false);
      isChanged(true);
      console.log(response.data);
    } catch (error) {
      console.error(error);
      setResponseMessage(
        "An error occurred while adding the blog. Please try adding images to both the cover image and the content, if you aren't already."
      );
      setIsError(true);
    }
  };

  const handleCoverImageChange = (e) => {
    setCoverImage(e.target.files[0]);
  };

  // Available fruits for selection (if needed)
  const fruits = [
    { value: "Apple", label: "Apple" },
    { value: "Banana", label: "Banana" },
    { value: "Orange", label: "Orange" },
    { value: "Mango", label: "Mango" },
    { value: "Grapes", label: "Grapes" },
    { value: "Pineapple", label: "Pineapple" },
  ];

  const handleCloseMessage = () => {
    setResponseMessage("");
  };

  return (
    <div>
      <HeadingForAll smallHeading={firstHeading} bigHeading={secondHeading} />
      <div className="mx-[30px] my-[2rem]">
        <form onSubmit={handleSubmit}>
          <div className="flex flex-grow mb-4 items-center">
            <div className="text-[20px] font-bold pr-[1rem]">
              Heading for your Blog:
            </div>
            <input
              type="text"
              placeholder="Blog Heading"
              onChange={(e) => setHeader(e.target.value)}
              className="border border-gray-400 p-2 w-[25rem] focus:outline-[#5ab963]"
            />
          </div>
          <div className="flex">
            <div className="flex flex-grow mb-4 items-center">
              <div className="text-[20px] font-bold pr-[1rem]">
                Cover Image for your Blog
              </div>
              <input
                type="file"
                accept="image/*"
                onChange={handleCoverImageChange}
                className="w-[15rem] border border-gray-400 p-2 focus:outline-[#5ab963]"
              />
            </div>
          </div>

          <div className="editor-container">
            {JoditEditor && editorConfig ? (
              <JoditEditor
                value={data}
                config={editorConfig}
                onChange={(value) => setData(value)}
              />
            ) : (
              <p>Loading editor...</p>
            )}
          </div>

          {/* Tags options (uncomment if needed)
          <div className="flex flex-grow mb-4 justify-center items-center">
            <div className="text-[18px] font-bold pr-[1rem] my-[2rem]">
              Select Tags:
            </div>
            <div>
              <Select
                className="min-w-[10rem] max-w-[20rem]"
                options={fruits}
                isMulti // Enable multi-select
                onChange={(values) => setSelectedFruits(values)}
                styles={customStyles}
              />
            </div>
          </div>
          */}

          <div className="flex justify-center items-center mt-[30px]">
            <button
              type="submit"
              className="bg-[#5ab963] hover:bg-[#48944f] text-white font-bold py-2 px-5 rounded"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      <div className="flex justify-center items-center w-full my-[2rem]">
        {/* Show response message if available */}
        {responseMessage && (
          <div
            className={`bg-${isError ? "red" : "green"}-100 border border-${
              isError ? "red" : "green"
            }-400 text-${isError ? "red" : "green"}-700 px-4 py-3 rounded absolute z-10`}
          >
            <p>{responseMessage}</p>
            <button
              onClick={handleCloseMessage}
              className="absolute top-0 right-0 -mt-1 -mr-1 p-1 rounded-full bg-white text-gray-500 hover:text-gray-600 focus:outline-none focus:ring focus:ring-gray-400"
            >
              <FaTimes className="h-4 w-4" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}