import React, { useState, useEffect } from "react";
import "./bottomNavbar.css";
import { Link } from "react-router-dom";
import {
  FaInstagram,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa6";
import { HashLink } from "react-router-hash-link";

const MainLogo = "/images/bottomNavbar/BottomNavbarLogo.png";

const navItems = [
  {
    id: 1,
    name: "Our Services",
    link: "/services/how-it-works",
    subItems: [
      { name: "Akiya Search", link: "akiya-search" },
      { name: "How it Works", link: "/services/how-it-works" },
      { name: "What it Costs", link: "/services/what-it-costs" },
    ],
  },
  {
    id: 2,
    name: "Our Projects",
    link: "our-projects",
    subItems: [{ name: "Akiya2.0 Concierge", link: "/akiya-concierge" }],
  },
  {
    id: 3,
    name: "Invest in Akiya",
    link: "invest-in-akiya",
    subItems: [],
  },
  {
    id: 4,
    name: "Community",
    link: "community",
    subItems: [
      { name: "News", link: "news" },
      { name: "Blogs", link: "blogs" },
    ],
  },
  {
    id: 5,
    name: "About Us",
    link: "about-us",
    subItems: [
      { name: "Akiya2.0 Team", link: "/about-us/#founders", isHashLink: true },
      { name: "Internship", link: "/internship/#interns", isHashLink: true },
    ],
  },
  {
    id: 6,
    name: null,
    subItems: [
      { name: "Term/Privacy", link: "terms", special: true },
      { name: "Contact Us", link: "/about-us/#contact", isHashLink: true },
    ],
  },
];

const socialLinks = [
  {
    name: "Instagram",
    icon: FaInstagram,
    link: "https://www.instagram.com/akiya2.0/",
  },
  {
    name: "LinkedIn",
    icon: FaLinkedin,
    link: "https://www.linkedin.com/company/akiya2-0-kk/about/",
  },
  {
    name: "YouTube",
    icon: FaYoutube,
    link: "https://www.youtube.com/channel/UCU9UXi25eRRuBUT0k9GeDzg",
  },
];

function BottomNavbar() {
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 1024);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const renderLogo = (containerClasses, imgClasses) => (
    <div className={`logo ${containerClasses}`}>
      <Link to="/">
        <img
          src={MainLogo}
          alt="Main Logo"
          className={imgClasses}
        />
      </Link>
    </div>
  );

  const renderNavItem = (item, isColumn = false) => (
    <div
      key={item.id}
      className={`px-3 ${isColumn ? "flex-col" : ""} ${
        !item.name ? "empty-nav-item" : ""
      }`}
    >
      <div className="flex-col-reverse text-[#fff]">
        {item.name && (
          <div className="text-[12px] font-[800] mb-[8px]">
            <Link to={item.link}>{item.name}</Link>
          </div>
        )}
        {item.subItems.map((subItem) => (
          <div
            key={subItem.name}
            className={`text-[12px] font-[400] leading-[24px] ${
              subItem.special ? "special-link" : ""
            }`}
          >
            {subItem.isHashLink ? (
              <HashLink smooth to={subItem.link}>
                {subItem.name}
              </HashLink>
            ) : (
              <Link to={subItem.link}>{subItem.name}</Link>
            )}
          </div>
        ))}
      </div>
    </div>
  );

  const renderSocialLinks = () => (
    <div className="flex text-[12px] font-[800] mb-[8px]">
      {socialLinks.map((social) => (
        <a
          key={social.name}
          href={social.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <social.icon className="text-[1rem] m-2" />
        </a>
      ))}
    </div>
  );

  const renderDesktopView = () => (
    <div className="bottom-navigation-container w-full bg-[#0a0e1a] pb-[150px] px-[25px] pt-[90px] z-50 flex justify-center">
      <div className="flex justify-center gap-1 w-full max-w-[1440px]">
        <div className="w-[18%]">
          {renderLogo("w-[180px] max-w-[180px] flex items-center", "h-auto w-full")}
        </div>
        {navItems.map(renderNavItem)}
        <div className="px-3">
          <div className="flex-col-reverse text-[#fff]">
            {renderSocialLinks()}
          </div>
        </div>
      </div>
    </div>
  );

  const renderTabletView = () => (
    <div className="bottom-navigation-container w-full bg-[#0a0e1a] pb-[150px] px-[25px] pt-[90px] z-50 flex justify-center">
      <div className="flex justify-center gap-2 w-full max-w-[1440px]">
        <div className="w-[25%]">
          {renderLogo("w-full flex items-center", "h-auto w-full max-w-[180px]")}
        </div>
        <div className="px-3 flex-col-reverse text-[#fff]">
          {navItems.slice(0, 3).map((item) => renderNavItem(item, true))}
        </div>
        <div className="px-3 flex-col-reverse text-[#fff]">
          {navItems.slice(3, 5).map((item) => renderNavItem(item, true))}
        </div>
        <div className="px-3 flex-col-reverse text-[#fff]">
          {renderNavItem(navItems[5], true)}
          {renderSocialLinks()}
        </div>
      </div>
    </div>
  );

  const renderSmallTabletView = () => (
    <div className="bottom-navigation-container w-full bg-[#0a0e1a] pb-[100px] px-[25px] pt-[60px] z-50 flex justify-center">
      <div className="flex justify-center gap-2 w-full max-w-[1440px]">
        <div className="w-[30%]">
          {renderLogo("w-full flex items-center", "h-auto w-full max-w-[180px]")}
        </div>
        <div className="px-3 flex-col-reverse text-[#fff]">
          {navItems.slice(0, 3).map((item) => renderNavItem(item, true))}
        </div>
        <div className="px-3 flex-col-reverse text-[#fff]">
          {navItems.slice(3).map((item) => renderNavItem(item, true))}
          {renderSocialLinks()}
        </div>
      </div>
    </div>
  );

  const renderMobileView = () => (
    <div className="bottom-navigation-container w-full bg-[#0a0e1a] pb-[70px] px-[25px] pt-[40px] z-50 flex justify-center">
      <div className="flex flex-col items-center w-full max-w-[1440px]">
        {renderLogo("w-full flex justify-center mb-10", "h-auto w-full max-w-[180px]")}
        <div className="px-3 text-center text-[#fff]">
          {navItems.map((item) => renderNavItem(item, true))}
          <div className="flex justify-center">{renderSocialLinks()}</div>
        </div>
      </div>
    </div>
  );

  // Use a default view for server-side rendering
  const defaultView = renderDesktopView();

  // Only use client-side detection if running in the browser
  if (isClient) {
    if (windowWidth >= 1024) {
      return renderDesktopView();
    } else if (windowWidth >= 768 && windowWidth < 1024) {
      return renderTabletView();
    } else if (windowWidth < 768 && windowWidth > 540) {
      return renderSmallTabletView();
    } else {
      return renderMobileView();
    }
  }

  // Return the default view for server-side rendering
  return defaultView;
}

export default BottomNavbar;