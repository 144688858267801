const images = ["/images/buy-an-akiya/img01.jpeg", "/images/buy-an-akiya/img02.jpeg", "/images/buy-an-akiya/img03.png", "/images/buy-an-akiya/img04.jpeg", "/images/buy-an-akiya/img05.jpeg", "/images/buy-an-akiya/img06.jpeg"];

function importAll(r) {
  r.keys().forEach((key) => images.push(r(key)));
}

// try {
//   importAll(
//     require.context(
//       "/images/buy-an-akiya",
//       false,
//       /\.(png|jpe?g|svg)$/
//     )
//   );
// } catch (error) {
//   console.error(error);
// }

export const headingData = [
  {
    heading: "Acquire an Akiya with ease",
    info: "In Japan, an abundance of abandoned or vacant properties awaits your consideration for purchase. While pinpointing exact figures remains challenging, rest assured, they exist. However, acquiring these properties has traditionally posed substantial challenges. The complexities span issues of data governance, cultural narratives, and industry norms, forming formidable obstacles for all but the most determined buyers. Our role is to simplify this process—facilitating your search, assessment, and acquisition of Akiya tailored to your preferences.",
  },
  {
    heading: "Purchase your Akiya today!",
    info: "Ready to transform abandoned spaces into your dream property? Take the first step by exploring unique Akiya opportunities. For personalized assistance or inquiries, Contacting Us is just a message away. Let's start your Akiya journey together!",
  },
];

export const subHeadingdata = [

  {
    number: "01",
    heading: "Acquire an Akiya with ease",
    title: "Searching for the right Akiya",
    subtitle: [
      "Comprehensive online database",
      "Advanced filters",
      "Interactive maps",
    ],
    content: [
      "Explore Akiya2.0's vast database of akiya houses, providing detailed information and images to help you find your dream property in Japan, completely free",
      "Utilize advanced search filters to narrow down your options based on preferences such as location, budget, and specific features, ensuring a tailored house-hunting experience.",
      "Visualize property locations through interactive maps, offering a seamless way to explore neighborhoods and assess the surroundings of potential akiya houses.",
    ],
    imageUrls: [images[0], images[1]],
  },
  {
    number: "02",
    title: "Contracting and legal - dealing with the paperwork",
    subtitle: [
      "Personalized Guidance",
      "Local Government Insight",
      "Smooth Transaction Support",
    ],
    content: [
      "Akiya2.0 can connect you with experienced real estate agents specializing in akiya properties, receiving personalized guidance and expert advice throughout your home-buying journey.",
      "We embark on a journey to secure your akiya home effortlessly. Our unique advantage lies in deep-rooted relationships and expertise, working seamlessly with local government bodies. Trust us to navigate intricacies, ensuring a smooth, efficient process for your akiya acquisition.",
      "Explore a buyer service that is not only knowledgeable but also seasoned in the akiya market. At Akiya2.0, where we're buyers too, our firsthand experience provides us with unique insights as both buyers and property innovators.",
    ],
    imageUrls: [images[2], images[3]],
  },
  {
    number: "03",
    title: "Conduct your dream Akiya makeover - Sustainably",
    subtitle: [
      "Architecture expertise",
      "Sustainable architecture plans",
      "Transparent budgeting",
    ],
    content: [
      "Draw upon the mastery of our in-house architects, seasoned in the art of renovation. With rich experience, they will skillfully transform your akiya house into a personalized, stunning living space, embracing the beauty of tradition.",
      "We invite you to partner with our in-house architects to craft personalized renovation blueprints, aligning with your preferences. We strive to maintain the traditional aesthetics of the akiya house, ensuring a unique blend of your style and timeless tradition.",
      "Enjoy transparent budgeting for your renovation project, with clear cost breakdowns and regular updates, allowing you to plan effectively and make informed decisions throughout the renovation process.",
    ],
    imageUrls: [images[4], images[5]],
  },
];

