import React, { useEffect, useState } from "react";
import axios from "axios";

import { useParams } from "react-router-dom";
import Gallery from "./Gallery";
import AllInOne from "./AllInOne";
import Section from "./Section";
import FirstSection from "./Three_Sections_Data/FirstSection";
import SecondSection from "./Three_Sections_Data/SecondSection";
import ThirdSection from "./Three_Sections_Data/ThirdSection";
import { Link } from "react-router-dom";
import Loader from "../Loader/Loader";
function IndividProp() {
  const { propertyId } = useParams();
  const [openSectionIndex, setOpenSectionIndex] = useState(null);
  const [propertyData, setPropertyData] = useState(null);

  // console.log(propertyId, "propertyData")
  const handleSectionClick = (index) => {
    setOpenSectionIndex(index === openSectionIndex ? null : index);
  };

  useEffect(() => {

    axios
      .get(`https://app.akiya2.com/one_property/${propertyId}`)
      .then((response) => {

        // Update the state with the fetched data
        console.log(response.data);
        setPropertyData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [propertyId]); // Add propertyId as a dependency

  // Assume property data is fetched here, or use the static data you provided
  // const property = {
  //   name: "Shichimi Boathouse",
  //   id: "AKIYA2-0000",
  //   images: [
  //     "https://assets-global.website-files.com/64e2ef9828bd89ba6ecd0d14/6566e096bdd47e1afd41ce78_shichimi.jpg",
  //     "https://assets-global.website-files.com/64e2ef9828bd89ba6ecd0d14/656c87acccc6bb0b43bc22ee_P3900140.jpg",
  //     "https://assets-global.website-files.com/64e2ef9828bd89ba6ecd0d14/656c87ac0e6333409310f497_P3900147.jpg",
  //     "https://assets-global.website-files.com/64e2ef9828bd89ba6ecd0d14/656c87ab807bedc3f3cba5ca_P3900148.jpg",
  //     "https://assets-global.website-files.com/64e2ef9828bd89ba6ecd0d14/656c87ac15901291e80c7193_P3900157.jpg",
  //     "https://assets-global.website-files.com/64e2ef9828bd89ba6ecd0d14/656c87ab6e8d27156371752e_P3900160.jpg",
  //     "https://assets-global.website-files.com/64e2ef9828bd89ba6ecd0d14/656c87acef9734857eace685_P3900165.jpg",
  //     "https://assets-global.website-files.com/64e2ef9828bd89ba6ecd0d14/656c87ab0e6333409310f48c_P3900171.jpg",
  //     "https://assets-global.website-files.com/64e2ef9828bd89ba6ecd0d14/656c87ac1c49ad5ab2a1310e_P3900177.jpg",
  //     "https://assets-global.website-files.com/64e2ef9828bd89ba6ecd0d14/656c87ab3f3639957446f5dd_P3900183.jpg",
  //   ],
  //   videoLink: "https://example.com/video",
  //   location: "Ha-34 Shichimi, Noto, Hosu District, Ishikawa 927-0301, Japan",
  //   distanceFromAirport: ["Noto Satoyama Airport", "45 minute drive"],
  //   checkInTime: "3:00 PM",
  //   checkOutTime: "10:00 AM",
  //   accommodations: "2-8 people",
  //   size: "3000 sq. ft",
  //   about:
  //     "Nestled in Shichimi, Noto, Ishikawa, our picturesque coastal property awaits. Adjacent to a seawall, this unique purchase encompasses two plots, with an inquiry into an adjoining one. Featuring two wood structures—one serving as a boathouse and the other at the back—the latter may undergo redevelopment. Envisage a seaside retreat in the making.",
  // };

  return (

    <>
      {propertyData ? (

    <div className="flex flex-col justify-center items-center">
          <div className="max-w-[1440px]">


        <div>
          <Gallery images={propertyData.images} video={propertyData.video} />
          <AllInOne property={propertyData} />
          <Section
            header="MAPS AND ACCESS"
            data={<FirstSection data={propertyData} />}
            isOpen={openSectionIndex === 0}
            onClick={() => handleSectionClick(0)}
          />
          <Section
            header="AMENITIES"
            data={<SecondSection data={propertyData} />}
            isOpen={openSectionIndex === 1}
            onClick={() => handleSectionClick(1)}
          />
          <Section
            header="BLOGS"
            data={<ThirdSection data={propertyData} />}
            isOpen={openSectionIndex === 2}
            onClick={() => handleSectionClick(2)}
          />
          <div className="h-[100%] w-[100%] flex justify-center items-center">
            <button className="mt-[5rem] r_xs:mt-[2.7rem] r_xxs:mt-[2.7rem] mb-[2rem] bg-[#5ab963] rounded font-[800] text-white px-[1.4rem] py-[0.7rem]">
              <Link to={"/our-projects"}>Go Back</Link>
            </button>
          </div>
        </div>

    </div>
    </div>
      ):<Loader/>}
    </>
  );
}

export default IndividProp;
