import React, { useEffect, useState } from "react";
import HeadingForAll from "../HeadingForAll/HeadingForAll";
import { useUpdateUser, useUser } from "../../Context/UserContext";
import { fetchData } from "../../Utils/CommonFunctions";
import AkiyaCard from "../AkiyaCard/AkiyaCard";
import CustomButt from "../CustomButton/CustomButt";
import { useNavigate } from "react-router-dom";
import Twitter from "../Svgs/Twitter";
import LinkedIn from "../Svgs/LinkedIn";
import Facebook from "../Svgs/Facebook";
import QuestionBox from "../QuestionBox/QuestionBox";
import cheerio from "cheerio";
import BlogCard from "../blogCard/BlogCard";
import ProfileBLogCard from "../blogCard/ProfileBlogCard";


const userImg = "/images/OtherImages/user.png";

const Profile = () => {
  const user = useUser();
  const updateUser = useUpdateUser();
  const navigate = useNavigate();
  const [favorites, setFavorites] = useState([]);
  const [blogs, setBlogs] = useState([]);
  if (!user) {
  }

  const {
    image_url,
    name,
    email,
    about_them,
    favorite,
    linkedin_link,
    twitter_link,
    blog,
  } = user;

  useEffect(() => {
    const { favorite } = user;
    if (favorite) {
      let tempData = [];
      const fetchPromises = []; // Array to store all fetch promises

      for (let id of favorite) {
        const promise = fetchData(
          `https://app.akiya2.com/one_listing/${id}`
        ).then((data) => {
          tempData.push(data);
        });
        fetchPromises.push(promise); // Store the promise in the array
      }

      // Wait for all promises to resolve
      Promise.all(fetchPromises).then(() => {
        setFavorites(tempData);
      });
    }
  }, [user]);
  useEffect(() => {
    if (blog) {
      let tempData = [];

      const fetchPromises = []; // Array to store all fetch promises

      for (let index of blog) {
        const id = index;
        const promise = fetchData(`https://app.akiya2.com/blog/${index}`)
          .then((data) => {
            // Extracting necessary information from the fetched data
            const {
              author,
              createdAt,
              blog_heading,
              blog_content,
              coverImage,
              index,
            } = data;

            // Load HTML content into cheerio
            const $ = cheerio.load(blog_content);
            const formattedDate = new Date(createdAt).toLocaleDateString(
              "en-US",
              {
                month: "long",
                day: "numeric",
                year: "numeric",
              }
            );

            // Extract content limited to 60 words from the first <p> tag
            const content = $("p")
              .first()
              .text()
              .split(" ")
              .slice(0, 30)
              .join(" ");

            // Push data to tempData
            tempData.push({
              author,
              date: formattedDate,
              title: blog_heading,
              content,
              imageUrls: coverImage,
              index: id,
            });
          })
          .catch((error) => {
            console.error("Error fetching blog data:", error);
          });

        fetchPromises.push(promise); // Store the promise in the array
      }

      // Wait for all promises to resolve
      Promise.all(fetchPromises).then(() => {
        console.log(tempData);
        setBlogs(tempData);
      });
    }
  }, [blog]);

  const handleDelete = (id) => {
    console.log("delete clicked");
    console.log(favorite);
    const tempFavorites = favorite.filter((f) => f !== id);
    console.log(tempFavorites);

    const updatedUser = { ...user, favorite: tempFavorites };
    console.log(updatedUser);
    updateUser(updatedUser);
  };

  return (
    <div className="relative ">
      <HeadingForAll bigHeading={"My Profile"} isProfile={true} />

      <div className="flex justify-center my-[1rem]  ">
        <div className="max-w-[1440px] w-[100vw]   mx-[1rem]  ">
          <div className="w-full flex md:flex-row flex-col gap-2">
            <div className="md:w-[32%] w-full   px-5 flex flex-col gap-5 items-center ">
              <div className="w-[10rem] h-[10rem]   mt-4 ">
                {user.image_url ? (
                  <img
                    src={`https://${image_url}`}
                    className="w-full h-full object-cover rounded-full"
                  />
                ) : (
                  <img
                    src={userImg}
                    className="w-full h-full object-cover rounded-full"
                  />
                )}
              </div>
              <p className="font-bold text-2xl">{name}</p>
              <p className="text-[--dark-grey] sm:text-lg font-bold text-xl ">
                {email}
              </p>
              <p className="text-center text-light text-[--dark-grey] font-light ">
                {about_them}
              </p>
              <div className="w-full  flex justify-around">
                <div className=" flex justify-end ">
                  <div className="w-[3rem] h-[3rem] ">
                    <a
                      href={twitter_link}
                      target="_blank"
                      className=" hover:text-black hover:stroke-white"
                    >
                      <Twitter />
                    </a>
                  </div>
                </div>
                <div className="flex justify-end ">
                  <div className="w-[3rem] h-[3rem] ">
                    <a
                      href={linkedin_link}
                      target="_blank"
                      className="  hover:text-black hover:stroke-white"
                    >
                      <LinkedIn />
                    </a>
                  </div>
                </div>
                <div className="flex justify-end ">
                  <div className="w-[3.7em] h-[3.7rem] ">
                    <a
                      href={linkedin_link}
                      target="_blank"
                      className="  hover:text-black hover:stroke-white"
                    >
                      <Facebook />
                    </a>
                  </div>
                </div>
              </div>
              <QuestionBox />
            </div>

            <div className="w-full   ">
              <div className="text-2xl  font-bold px-3 py-5 border ">
                My Favorites
              </div>
              <div>
                {favorites.length ? (
                  <div className=" mt-4 mb-5  grid xl:grid-cols-3 sm:grid-cols-2 py-3   gap-8 h-[34rem] overflow-scroll">
                    {favorites.map((item) => (
                      <AkiyaCard
                        item={item}
                        isFavorite={true}
                        onDelete={handleDelete}
                      />
                    ))}
                  </div>
                ) : (
                  <div className=" w-full mb-5 pl-2 mt-10  inline-flex gap-4 items-center">
                    <p className="text-3xl"> Nothing on Favorites: </p>
                    <CustomButt
                      text={"Add to Favorites"}
                      handleButtonClick={() => navigate("/akiya-search")}
                    />
                  </div>
                )}
              </div>

              <div className="text-2xl font-bold px-3 py-5 border flex justify-between items-center ">
                My blogs
                {blogs.length ? (
                  <button
                    onClick={() => navigate("/blog-writing")}
                    className={`custom-button bg-[var(--medium-sea-green)] hover:bg-[var(--sea-green)] inline-block  text-white font-semibold py-2 px-4 rounded-lg text-lg`}
                  >
                    <span>Add a blog</span>
                  </button>
                ) : null}
              </div>
              <div>
                {blog.length ? (
                  <div className=" mt-4 mb-10  grid xl:grid-cols-3 sm:grid-cols-2 py-3   gap-8 h-[34rem] overflow-scroll">
                    {blogs.map((item) => (
                      <ProfileBLogCard item={item} />
                    ))}
                  </div>
                ) : (
                  <div className=" w-full pl-2 mt-10  inline-flex gap-4 items-center">
                    <p className="text-3xl"> Create your first blog: </p>
                    <CustomButt
                      text={"Add Blog"}
                      handleButtonClick={() => navigate("/blog-writing")}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
