import React from "react";
import AkiyaEcosystem from "./AkiyaEcosystem";
import Test2 from "./Test2";
import Test3 from "./Test3";
import QuestionBox from "../QuestionBox/QuestionBox";
import HeadingForAll from "../HeadingForAll/HeadingForAll";
import OuServiceIcon from "./OurServicesSvg";
import useFetchSVG from "../useFetchSVG";
import ourServiceSvg from "../Svgs/OurServices.svg";
import AkiyaForm from "../akiyaForm/AkiyaForm";


import { headingData, subHeadingdata } from "../buyAnAkiya/BuyAnAkiyaHook";

import Heading from "../headingContainer/Heading";

function Test() {
  const svgContent = useFetchSVG("../Svgs/OurServices.svg"); // Adjust the path as necessary

  const heading = "A complete soup to nuts akiya consultation ecosystem";
  const paragraph = (
    <>
      Our experts will help you buy and renovate your Akiya! Akiya2.0’s
      consultancy service will pair you with the perfect kominka that is right
      for you. We use our connections and resources in Japan to connect you to
      your authentic Japanese property without the hassle of researching akiya
      and working with municipal governments. We enable both you and the
      community to meaningfully activate properties, benefitting our clients
      with up-and-coming real estate, and the community surrounding it by
      reviving an empty property.
    </>
  );

  const firstHeading = "Our services";
  const secondHeading = "Your way into rural Japanese real estate";

  return (
    <div>
      <HeadingForAll smallHeading={firstHeading} bigHeading={secondHeading} />
      <div className="text-justify mx-[30px] text-[22px] leading-[36px] my-[3rem]">
        <div>
          <h3 className="font-[800] text-[32px] mb-3">{heading}</h3>
        </div>
        <div>
          <h5 className="text-[#949995] font-[300]">{paragraph}</h5>
        </div>
      </div>
      <div className="relative flex justify-center items-center h-screen w-screen">
        {/* Center text */}
        <h2
          className="absolute z-10 text-green-400 p-2 text-center"
          style={{ fontSize: "clamp(1.5rem, 2.5vw, 3rem)" }}
        >
          Akiya2.0
          <br />
          Consultation
          <br />
          Service Ecosystem
        </h2>

        <OuServiceIcon />
        {/* style={{ marginTop: '-2rem' }} */}
        {/* 1] */}
        <div className="absolute top-[20%] right-[2rem] text-left w-[20%]" >
          <h3 className="text-lg">1) Find and Purchase your Akiya</h3>
          <div className="font-bold">
            <p>Search Assistance</p>
            <p>Market Analysis (Optional)</p>
            <p>Site Inspection</p>
            <p>Regulatory Advisory</p>
            <p>Buyer Side Negotiations</p>
            <p>Contract Liaison</p>
            <p>Other Language Services</p>
          </div>
        </div>

        {/* 2] */}
        <div className="absolute bottom-[20%] right-[2rem] text-left w-[20%]">
          <h3 className="text-lg">2) Renovate your dream Japanese home</h3>
          <div className="font-bold">
            <p>Project Scoping</p>
            <p>Visualisation Service</p>
            <p>Costing/ Contractors/ Timetable</p>
            <p>Renovation and monitoring</p>
            <p>Contractor management</p>
            <p>Buyer Visit Coordination</p>
            <p>Moving in/ Local Activation</p>
          </div>
        </div>

        {/* 3] */}
        <div className="absolute bottom-[20%] left-[2rem] text-right w-[20%]">
          <h3 className="text-lg">
            3) Activate/ Operate your Akiya investment
          </h3>
          <div className="font-bold">
            <p>Market Analysis</p>
            <p>Marketing</p>
            <p>Property Management</p>
            <p>Reservation Management (Optional)</p>
            <p>Corporate Presence (Optional)</p>
          </div>
        </div>

        {/* 4] */}
        <div className="absolute top-[20%] left-[2rem] text-right w-[20%]">
          <h3 className="text-lg">
            4) Leave your Akiya better than you first invested in it.
          </h3>
          <div className="font-bold">
            <p>Marketing and Listing</p>
            <p>Moving out/Deep Clean</p>
            <p>Contract Liaison</p>
          </div>

</div>

      </div>


      {/* <Test3 /> */}
      {/* <div className='h-[10vh] w-full'></div> */}
      <Test2 />
      <div className="h-[10vh] w-full"></div>
      <div className="w-full flex items-center justify-center">

        <div className="flex flex-col md:flex-row w-full max-w-[1440px] my-5 gap-12 mx-[3rem]">
          <div className=" md:w-[50%] w-full p-2">
            <Heading data={headingData[1]} />
          </div>
          <div className=" md:w-[50%] w-full">
            <AkiyaForm />
          </div>
        </div>
      </div>
      {/* <QuestionBox /> */}
    </div>
  );
}

export default Test;
