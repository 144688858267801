import React from "react";
import Heading from "../headingContainer/Heading";
import HeadingForAll from "../HeadingForAll/HeadingForAll";
import SubHeadCont from "../subHeadingContainer/SubHeadCont";
import { headingData, subHeadingdata } from "./BuyAnAkiyaHook";
import Form from "../akiyaForm/Form";
import { Link } from "react-router-dom";
import SliderImagesContainerForInvestor from "./SliderImagesContainerForInvestor";
import { shimanamiKaido } from "./InvestInAkiyaData";
import { noto } from "./InvestInAkiyaData";
import FourthSOV from "../ourProjects/FourthSOV";

console.log("Heading",Heading);
console.log("HeadingForAll",HeadingForAll);
console.log("SubHeadCont",SubHeadCont);
console.log("SliderImagesContainerForInvestor",SliderImagesContainerForInvestor);

function InvestInAkiya() {
  const images = [shimanamiKaido.imagesLinks[0], shimanamiKaido.imagesLinks[1], shimanamiKaido.imagesLinks[2]];
  const headers = [noto.data.title, shimanamiKaido.data.title, shimanamiKaido.data.title];
  const subHeaders = [noto.data.about, shimanamiKaido.data.about, shimanamiKaido.data.about];
  const paragraphs = [noto.data.largeAbout, shimanamiKaido.data.largeAbout, shimanamiKaido.data.largeAbout];
  const makers = [shimanamiKaido.data.maker1, shimanamiKaido.data.maker, noto.data.maker];

  const heading = "The Akiya2.0 Thesis";
  const paragraph =
    "Both our Consultancy (Our Service) and our Travel club (Our Project) are based upon 3 theses we have with regards to how the Akiya crisis can be most effectively and sustainably resolved in a way that benefits both foreign investors and local communities. ";

  const firstHeading = "Our Thesis";
  const secondHeading = "Your way into rural Japanese real estate";
  const oneLiner = "Learn more about Akiya2.0 Travel Club and join us today!";
  const boxHeader = "Shimanami Kaido";
  const boxHeader2 = "‍Cycling Paradise of the Seto Inland Sea";
  const boxPara =
    "The Shimanami Kaido unfolds as a cycling paradise connecting the islands of the Seto Inland Sea. Celebrated for its scenic landscapes and well-crafted cycling routes, it provides a harmonious blend of nature and infrastructure. Whether an avid enthusiast or a casual rider, the Shimanami Kaido offers an unforgettable journey through the breathtaking beauty of the Seto Inland Sea.";

  return (
    <>
      <HeadingForAll smallHeading={firstHeading} bigHeading={secondHeading} />
      <div className="flex justify-center">
        <div className="max-w-[1440px]">
          <div className=" h-[100%] w-[100vw] max-w-[1440px] mt-[1rem]">
            <div className="text-justify mx-[30px] text-[22px] leading-[36px] my-[3rem] ">
              <div>
                <h3 className="font-[800] text-[32px] mb-3">{heading}</h3>
              </div>
              <div>
                <h5 className="text-[#949995] font-[300]">{paragraph}</h5>
              </div>
            </div>

            <div className="flex justify-center gap-3 my-[2rem] ">
              <div className=" max-w-[1440px] lg:mx-[3rem]  md:mx-[2rem] mx-[1rem] flex flex-col gap-8  ">
                <div className=" flex flex-col gap-8 mt-16">
                  {subHeadingdata.map((item, index) => {
                    return <SubHeadCont data={subHeadingdata[index]} />;
                  })}
                </div>
              </div>
            </div>

<div className="w-[100vw] max-w-[1440px] flex justify-center">

              {/* Sliding Images */}
                <SliderImagesContainerForInvestor
                  images={images}
                  headers={headers}
                  subHeaders={subHeaders}
                  paragraphs={paragraphs}
                  makers={makers}
                />
</div>

            {/* one liner and the button */}
            <div className="my-[4rem]">
              <div className="flex justify-center items-center">
                <h1 className="text-[#5ab963] text-[24px] font-[800] leading-[36px] text-center">
                  {oneLiner}
                </h1>
              </div>
              <div className="flex justify-center items-center">
                <Link to="/our-projects">
                  <button className=" w-[100vw] md:w-auto md:rounded-md bg-[#5ab963] px-[30px] py-[18px] text-white text-[16px] font-[800]">
                    Our Projects
                  </button>
                </Link>
              </div>
            </div>

            <div className="flex flex-col md:flex-row w-full my-5 gap-12 mx-[30px]">
              <div className=" md:w-[50%] w-full p-2">
                <Heading data={headingData[1]} />
              </div>
              <div className=" md:w-[50%] w-full">
                <Form />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InvestInAkiya;
export {FourthSOV};