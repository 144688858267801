import React, { useContext, useState } from "react";
import "./Navbar.css";
import { Link, useNavigate } from "react-router-dom";
import Modal from "./RegisterAnd Login/Modal";
import { UserContext } from "../../Context/UserContext";
import User from "../Svgs/User";
import useWindowDimensions from "../useWindowDimensions";

import Menu from "../Svgs/Menu";

// const MainLogo = "/images/MainLogo.png";
const Nav = () => {
  const { showModal, setShowModal } = useContext(UserContext);
  const { width } = useWindowDimensions();
  const [navbarDropDown, setNavbarDropDown] = useState(false);
  const [servicesDropdown, setServicesDropdown] = useState(false);
  const { user, setUser, setIsToken } = useContext(UserContext);
  const navigate = useNavigate();

  const handleModalOpen = () => {
    if (user) {
      navigate("/profile-page");
    } else {
      setShowModal(true);
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("access_token");
    setIsToken(false);
    setUser(null);
    navigate("/");
  };

  // Navigation links array with dropdown
  const navLinks = [
    { to: "/akiya-search", text: "Akiya Search" },
    {
      text: "Our Services",
      dropdown: [
        { to: "/services/how-it-works", text: "How It Works" },
        { to: "/services/what-it-costs", text: "What It Costs" },
      ],
    },
    { to: "/our-projects", text: "Our Projects" },
    { to: "/invest-in-akiya", text: "Invest in Akiya" },
    { to: "/community", text: "Community" },
  ];

  return (
    <div className="relative">
      <div className="navigation-container h-[5rem] w-[100%] bg-[#5ab963] sticky top-0 z-50 flex justify-center">
        <div className="flex h-[100%] w-[100%] pl-[30px] justify-between max-w-[1440px]">
          <div className="logo h-[100%] w-[140px] flex items-center">
            <Link to="/">
              <img src="/images/MainLogo.jpeg" alt="Akiya2.0 logo" />
            </Link>
          </div>
          {width > 850 ? (
            <div className="flex md:w-[90%] xl:w-[60%] lg:w-[70%]">
              {navLinks.map((link, index) => (
                <div
                  key={index}
                  className="logo h-[100%] w-[16.5%] flex justify-center items-center text-white text-[14px] font-[600] leading-[24px] relative"
                  onMouseEnter={() =>
                    link.text === "Our Services" && setServicesDropdown(true)
                  }
                  onMouseLeave={() =>
                    link.text === "Our Services" && setServicesDropdown(false)
                  }
                >
                  {link.dropdown ? (
                    <div className="relative">
                      <button className="transition-all duration-300 hover:[text-shadow:_1px_2px_2px_rgb(21_12_11_/_40%)]">
                        {link.text}
                      </button>
                      {servicesDropdown && (
                        <div className="absolute left-[-50%] top-[2rem] border-x-2 border-white border-2 bg-[--medium-sea-green] text-center flex flex-col text-white w-[11rem]">
                          {link.dropdown.map((subLink, subIndex) => (
                            <Link
                              key={subIndex}
                              to={subLink.to}
                              className="w-full p-2 hover:bg-[--sea-green]"
                            >
                              {subLink.text}
                            </Link>
                          ))}
                        </div>
                      )}
                    </div>
                  ) : (
                    <Link
                      to={link.to}
                      className="transition-all duration-300 hover:[text-shadow:_1px_2px_2px_rgb(21_12_11_/_40%)]"
                      onClick={() => setNavbarDropDown(false)} // Close dropdown on link click
                    >
                      {link.text}
                    </Link>
                  )}
                </div>
              ))}
              {user ? (
                <div
                  className="w-[12%] h-[3rem] my-3 relative ring-2 ring-white ring-inset rounded logo flex justify-center items-center text-white text-[14px] font-[600] leading-[24px]"
                  onMouseEnter={() => setNavbarDropDown(true)}
                  onMouseLeave={() => setNavbarDropDown(false)}
                >
                  <div className="h-10 w-10">
                    <Menu />
                  </div>
                  <button onClick={handleModalOpen}>
                    <div className="h-10 w-10">
                      <User />
                    </div>
                  </button>
                  {navbarDropDown && (
                    <div className="absolute top-[3rem] border-x-2 border-white border-2 bg-[--medium-sea-green] text-center flex flex-col text-white w-[6rem]">
                      <Link
                        to="/profile-page"
                        className="w-full p-2 hover:bg-[--sea-green]"
                      >
                        Profile
                      </Link>
                      <button
                        onClick={handleLogout}
                        className="w-full p-2 hover:bg-[--sea-green]"
                      >
                        Logout
                      </button>
                      <Link
                        to="/profile-settings"
                        className="w-full p-2 hover:bg-[--sea-green]"
                      >
                        Settings
                      </Link>
                    </div>
                  )}
                </div>
              ) : (
                <button
                  onClick={handleModalOpen}
                  className="w-[12%] h-[2.5rem] my-5 hover:bg-white hover:text-black ring-2 ring-white ring-inset rounded-md logo flex justify-center items-center text-white text-[14px] font-[600] leading-[24px]"
                >
                  <p>Sign in</p>
                </button>
              )}
            </div>
          ) : (
            <button
              className="mr-4 text-white"
              onClick={() => setNavbarDropDown(!navbarDropDown)}
            >
              <div className="h-10 w-10">
                <Menu />
              </div>
            </button>
          )}
        </div>
      </div>
      <Modal show={showModal} onClose={handleModalClose} />
      {width <= 850 && (
        <div
          className={`navbarDropDown ${
            navbarDropDown ? "slide-down" : "slide-up"
          } bg-[--medium-sea-green] text-center flex flex-col w-full text-white`}
        >
          <ul>
            {navLinks.map((link, index) => (
              <li key={index} className="px-4 py-4 relative">
                {link.dropdown ? (
                  <div>
                    <button
                      className="w-full text-center"
                      onClick={() => setServicesDropdown((prev) => !prev)}
                    >
                      {link.text}
                    </button>
                    {servicesDropdown && (
                      <div className="w-full bg-[#4b9e53a0] text-white mt-[1.5rem] shadow-md">
                        {link.dropdown.map((subLink, subIndex) => (
                          <Link
                            key={subIndex}
                            to={subLink.to}
                            className="block m-0 p-2 hover:bg-[--sea-green]"
                            onClick={() => {
                              setServicesDropdown(false);
                              setNavbarDropDown(false);
                            }}
                          >
                            {subLink.text}
                          </Link>
                        ))}
                      </div>
                    )}
                  </div>
                ) : (
                  <Link
                    to={link.to}
                    onClick={() => setNavbarDropDown(false)} // Close dropdown on link click
                  >
                    {link.text}
                  </Link>
                )}
              </li>
            ))}
            {user && (
              <div className="flex flex-col gap-2 justify-center items-center">
                <Link
                  to="/profile-page"
                  className="logo h-[100%] pt-3 w-[50%] text-center justify-center items-center text-white text-[14px] font-[600] leading-[24px] mb-4"
                >
                  <p>Profile</p>
                </Link>
                <button
                  onClick={handleLogout}
                  className="ring-2 ring-white mt-2 ring-inset rounded hover:text-black hover:bg-white logo h-[100%] py-2 w-[50%] text-center font-lg justify-center items-center text-white text-[14px] font-[600] leading-[24px] mb-4"
                >
                  <p>Logout</p>
                </button>
              </div>
            )}
            {!user && (
              <button
                onClick={handleModalOpen}
                className="ring-2 ring-white ring-inset rounded hover:text-black hover:bg-white logo h-[100%] py-2 w-[50%] text-center font-lg justify-center items-center text-white text-[14px] font-[600] leading-[24px] mb-4"
              >
                <p>Sign in</p>
              </button>
            )}
          </ul>
        </div>
      )}
    </div>
  );
}


export default Nav;