import React from "react";
import { Link } from "react-router-dom";

// Define image URLs directly
const FirstSOVFullImage = "/images/Home/FirstSOVFullImage.jpeg";
const MainLogo = "/images/Navbar/MainLogo.jpeg";

function FirstSOV() {
  return (
    <section className="home-container h-[56vh] w-[100%]">
      <div
        className="firstSOVImage h-[100%] w-[100%] flex justify-center items-center py-[5%] px-[2%]"
        style={{
          backgroundImage: `url(${FirstSOVFullImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="max-w-[1440px]">
          <div className="group">
            <div className="relative">
              <div className="flex-col-reverse bg-gray-900/80 text-white justify-center items-center 2xl:px-[8rem] pt-[1rem] xl:px-[4rem] px-[1rem] max-w-[90vw]">
                <h1 className="mdlg:text-[72px] md:text-[60px] text-center text-[40px] font-[800] flex justify-center items-center md:leading-[4.5rem]">
                  <span>
                    Redefine your{" "}
                    <span>
                      <span className="text-green-500 mr-2 ml-1">Akiya</span>
                    </span>{" "}
                    experience
                  </span>
                </h1>
                <h1 className="text-[16px] font-[300] text-center flex justify-center items-center">
                  Own a slice of your Japanese countryside heaven
                </h1>
              </div>
              <span className="absolute font-[500] text-[15px] text-center right-[-9rem] -translate-x-1/2 top-full mb-2 w-[18rem] px-5 py-2 text-white bg-black opacity-0 group-hover:opacity-100 transition-opacity rounded-b-lg">
                Akiya is a traditional Japanese house that has been abandoned.
                At Akiya2.0, we are redefining these neglected houses by
                renovating them into authentic travel experiences.
              </span>
            </div>
          </div>
          <div className="flex justify-center">
            <Link to="our-projects">
              <button className="my-[1rem] text-[16px] font-[800] bg-[#5ab963] px-[30px] py-[15px] text-white rounded">
                Get Started
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FirstSOV;