import React from 'react';

// Manually define the URLs for the 8 images
const images = [
  "/images/akiya-concierge/img01.jpeg",
  "/images/akiya-concierge/img02.jpeg",
  "/images/akiya-concierge/img03.jpeg",
  "/images/akiya-concierge/img04.jpeg",
  "/images/akiya-concierge/img05.jpeg",
  "/images/akiya-concierge/img06.jpeg",
  "/images/akiya-concierge/img07.png",
  "/images/akiya-concierge/img08.jpeg",
];

// Static heading data (remains the same as your original code)
export const headingData = [
  {
    heading: "Akiya2.0 Concierge",
    info: "Elevate your akiya experience with Akiya2.0 Concierge, as we provide a central lobby location to help our traveler’s unique tastes...",
  },
  {
    heading: "Effortless Exploration",
    info: "To enhance the Akiya experience, we will customize transportation for each cluster...",
  },
  {
    heading: "Drive Into Tranquility",
    info: "Forget the hassles of busy trains and booking taxis, and be able to fully customize your vacation...",
  },
  {
    heading: "Local flavors, packaged",
    info: "We provide nothing but the best Japan has to offer. Indulge in our BBQ packages...",
  },
  {
    heading: "Bespoke dining experience",
    info: "Feeling luxurious? Opt to elevate your stay with Akiya2.0 by calling for a personal chef...",
  },
];

// Static data for slidingBoxRight (first 3 images)
export const slidingBoxRight = {
  boxHeader: "Transportation",
  boxHeader2: "‍‍Book an Akiya, get a car/electric bicycle!",
  boxPara:
    <>
      Embark on boundless exploration with Akiya2.0's innovative offer—Each reservation includes a complimentary vehicle, turning your stay into an adventure.
      <br />
      To compliment this, our 24/7 concierge, strategically located in central clusters, facilitates seamless experiences, ensuring your journey is as memorable as your destination.
    </>,
  images: images.slice(0, 3),  // Use images 1, 2, and 3
};

// Static data for slidingBoxLeft (next 3 images)
export const slidingBoxLeft = {
  boxHeader: "Culinary delights",
  boxHeader2: "‍Good food available at your fingertips",
  boxPara:
    <>
      Savor the flavors of the region with: 
      <br />
      <br />
      1] Akiya2.0's room service packages curated in collaboration with local chefs and farms.
      <br />
      2] Elevate your stay by opting for a personal chef for the night, creating a bespoke dining experience.
    </>,
  images: images.slice(3, 6),  // Use images 4, 5, and 6
};

// Static data for imgContentData (remaining images)
export const imgContentData = [
  {
    title: "Akiya2.0 Concierge",
    subtitle: "Seamless service at your fingertips",
    content:
      "We provide 24-hour staffed standby concierge, available remotely but also physically located in central clusters (e.g., Wajima City for Noto). Consider it a hotel lobby for our decentralized akiya villas. Rest in the comfort of know that rain or shine, our concierge is ready to serve at a moments notice to provide the best service available for you.",
    image: images[6],  // Use image 7
  },
  {
    title: "Smart appliances and technologies",
    subtitle: "Because rural does not have to mean uncomfortable",
    content:
      "At Akiya2.0, we supplement traditional living with our high-tech appliances and conveniences. Each house is equipped with cutting-edge technology, ensuring modern comforts without compromising on the essence of tradition.",
    image: images[7],  // Use image 8
  },
];