import React, { useState, useEffect, useRef, useContext } from "react";
import {
  useUpdateUser,
  useUser,
  useUpdateUserImage,
  UserContext,
} from "../../Context/UserContext";
import { useNavigate } from "react-router-dom";


const userImg = "/images/OtherImages/user.png";

function InputField({ label, name, value, type, onChange }) {
  return (
    <div className="">
      <label>{label}:</label>
      <input
        className="w-full mt-1 h-12 border-[1px] rounded-lg text-md text-[--dark-grey-2] placeholder-sm text-sm
          outline-none focus:outline-non pl-2"
        type={type}
        name={name}
        value={value}
        onChange={onChange}
      />
    </div>
  );
}

function ProfileSetting() {
  const [userData, setUserData] = useState();
  const user = useUser();
  const navigate = useNavigate();
  const fileInputRef = useRef(null); // Reference to the hidden file input element
  const [userImage, setUserImage] = useState();
  const [imageUrl, setImageUrl] = useState(null);
  const updateUserImage = useUpdateUserImage();

  useEffect(() => {
    if (user) {
      setUserData(user);
      console.log(user.image_url);
    }
  }, [user]);

  const updateUser = useUpdateUser();

  const handleChange = (event) => {
    const { name, value } = event.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    await updateUser(userData);
    await updateUserImage(userImage);
  
      navigate("/profile-page");

    // You can also handle any HTTP request errors here
  };

  const handleImageClick = () => {
    fileInputRef.current.click(); // Trigger click event on hidden file input
  };

  const handleImageChange = (event) => {
    event.preventDefault();

    const file = event.target.files[0];
    setUserImage(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }

    // Handle the selected image file here
    // You can use FileReader to read the file data, upload it, etc.
  };

  return (
    <div className="mx-auto flex justify-center mb-4     ">
      <div className="max-w-[1080px] w-full mx-4">
        <h1 className="text-2xl font-bold mb-4">Profile Settings</h1>
        <form onSubmit={handleSubmit} className="mt-5">
          <div>
            <div className="div"></div>
          </div>

          <div
            className="w-[12] h-[12rem] relative  flex justify-center items-center"
            // Add click handler to the green div
          >
            <div className="h-[12rem]   flex" onClick={handleImageClick}>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }} // Hide the file input
                onChange={handleImageChange} // Handle file selection
                accept=".jpg, .png, .jpeg ,.webP"
              />

              {!user.image_url && !imageUrl && (
                <div className="h-[11rem] w-[11rem] relative rounded-full cursor-pointer overflow-hidden">
                  <div
                    className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-green-600 hover:opacity-70 opacity-0 mask-image z-10 rotate-45"
                    style={{
                      maskImage: `url(${userImg})`,
                      WebkitMaskImage: `url(${userImg})`,
                    }}
                  >
                    <p className="rotate-[-45deg] translate-y-2 translate-x-2 text-white flex gap-2 ">
                      Edit
                    </p>
                  </div>
                  <img
                    src={`https:${user.image_url}`}
                    className="absolute bottom-0 left-0 w-full h-auto z-1"
                    alt="User"
                  />
                </div>
              )}
              {!imageUrl && user.image_url && (
                <div className="h-[11rem] w-[11rem]  relative rounded-full cursor-pointer overflow-hidden">
                  <div
                    className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-green-600 hover:opacity-70 opacity-0 mask-image z-10 rotate-45"
                    style={{
                      maskImage: `url(${userImg})`,
                      WebkitMaskImage: `url(${userImg})`,
                    }}
                  >
                    <p className="rotate-[-45deg] translate-y-2  translate-x-1 text-white flex gap-2 ">
                      Edit
                    </p>
                  </div>
                  <img
                    src={`https://${user.image_url}`}
                    className="absolute bottom-0 left-0 w-full h-full z-1"
                    alt="User"
                  />
                </div>
              )}
              {imageUrl && (
                <div className="h-[11rem] w-[11rem] relative rounded-full cursor-pointer overflow-hidden shadow-2xl">
                  <div
                    className="absolute top-0 left-0 w-full h-full flex justify-center items-center bg-green-600 hover:opacity-70 opacity-0 mask-image z-10 rotate-45"
                    style={{
                      maskImage: `url(${userImg})`,
                      WebkitMaskImage: `url(${userImg})`,
                    }}
                  >
                    <p className="rotate-[-45deg] translate-y-2 translate-x-2 text-white flex gap-2 ">
                      Edit
                    </p>
                  </div>
                  <img
                    src={imageUrl}
                    className="absolute bottom-0 left-0 w-full h-full z-1"
                    alt="User"
                  />
                </div>
              )}
            </div>
          </div>

          <InputField
            label="User Name"
            name="name"
            value={userData?.name}
            type="text"
            onChange={handleChange}
          />
          <InputField
            label="Email"
            name="email"
            value={userData?.email}
            type="email"
            onChange={handleChange}
          />
          <InputField
            label="First Name"
            name="first_name"
            value={userData?.first_name}
            type="text"
            onChange={handleChange}
          />
          <InputField
            label="Last Name"
            name="last_name"
            value={userData?.last_name}
            type="text"
            onChange={handleChange}
          />
          <InputField
            label="Facebook Link"
            name="facebook_link"
            value={userData?.facebook_link}
            type="text"
            onChange={handleChange}
          />
          <InputField
            label="Twitter Link"
            name="twitter_link"
            value={userData?.twitter_link}
            type="text"
            onChange={handleChange}
          />
          <InputField
            label="LinkedIn Link"
            name="linkedin_link"
            value={userData?.linkedin_link}
            type="text"
            onChange={handleChange}
          />
          <InputField
            label="About Them"
            name="about_them"
            value={userData?.about_them}
            type="text"
            onChange={handleChange}
          />
          {/* <InputField
          label="Password"
          name="password"
          value={userData?.password}
          type="password"
          onChange={handleChange}
        /> */}
          <div className="flex justify-center w-full">
            <button
              className="bg-[--medium-sea-green] px-6 hover:bg-[--sea-green] mt-3 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ProfileSetting;
