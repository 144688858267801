import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import BlogCard from "../blogCard/BlogCard";
import formatDate from "../../FormatDate";
import { FaLinkedin, FaTwitter } from "react-icons/fa";
import Loader from "../Loader/Loader";
import CardSlider2 from "../AkiyaOnNews/NewsCards/customCardSlider";
// import MetaTagsUpdater from "../MetaTagsUpdater";

function SingleBlog() {
  const [data, setData] = useState(null);
  const [latestBlogData, setLatestBlogData] = useState([]);
  const { blogId } = useParams();
  
  const currentUrl = `https://www.akiya2.com/blogs/${blogId}`;

  useEffect(() => {
    // Fetch single blog data
    axios
      .get(`https://app.akiya2.com/blog/${blogId}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching blog data:", error);
      });

    // Fetch latest blog data
    async function fetchData() {
      try {
        const response = await fetch(`https://app.akiya2.com/blogs`);
        const data = await response.json();
        const sortedData = data
          .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
          .slice(0, 5);
        setLatestBlogData(sortedData);
      } catch (error) {
        console.error("Error fetching latest blogs:", error);
      }
    }
    fetchData();
  }, [blogId]);

  const links = [
    {
      name: "LinkedIn",
      link: `https://www.linkedin.com/shareArticle?url=${currentUrl}`,
      icon: <FaLinkedin />,
    },
    {
      name: "Twitter",
      link: `https://twitter.com/intent/tweet?url=${currentUrl}`,
      icon: <FaTwitter />,
    },
  ];

  if (!data) {
    return <Loader big={false} />;
  }

  return (
    <div>
      {/* Helmet for SEO */}
      {/* <MetaTagsUpdater /> */}

      <div className="w-[100%] mb-[12rem]">
        <div className="w-[100%] h-[30rem] bg-[#5ab963]"></div>

        <div className="absolute h-[90vh] w-[100vw] flex justify-center top-0 ">
          <div className="max-w-[70%] w-[fitcontent] h-[43rem] mt-[4.8rem]">
            <img
              className="max-h-[100%]"
              src={data.coverImage}
              alt={data.blog_heading}
            />
          </div>
        </div>
      </div>

      <div className="px-[30px]">
        <div className="text-center text-[72px] font-[800] leading-[78px]">
          {data.blog_heading}
        </div>

        <div className="flex justify-between mx-10">
          <div className="text-[#5ab963] text-[24px] font-[800]">
            {formatDate(data.createdAt)}
          </div>
          <div className="text-[#5ab963] text-[24px] font-[800]">
            {data.author}
          </div>
        </div>

        <div className="flex justify-end m-3 my-[3rem]">
          <p className="text-[18px] leading-[20px] font-[400]">Share on:</p>
          {links.map((x) => (
            <Link key={x.name} to={x.link} className="mx-2">
              <div className="flex justify-around items-center">
                <div>{x.icon}</div>
                <p className="text-[#999] px-1 text-[18px] leading-[20px]">
                  {x.name}
                </p>
              </div>
            </Link>
          ))}
        </div>
      </div>

      <div className="mx-[30px] text-[20px] leading-[35px] font-[400] mb-[5rem] max-h-[100%] overflow-auto">
        <div
          className=" max-h-[100%] box-border"
          dangerouslySetInnerHTML={{ __html: data.blog_content }}
        />
      </div>

      <div className="max-w-[1440px] mt-[3rem]">
        <div className="text-center text-[#5ab963] text-[24px] font-[800] my-[2rem]">
          Latest Blogs
        </div>
        {latestBlogData.length ? (
          <div className="w-full flex items-center justify-center my-10">
            <div className="w-[95%]">
              <CardSlider2
                data={latestBlogData}
                Card={BlogCard}
                endValues={{
                  xs: 1,
                  md: 2,
                  lg: 3,
                }}
              />
            </div>
          </div>
        ) : (
          <Loader big={false} />
        )}
      </div>

      <div className="mx-5 flex mb-[5rem] mt-[10rem]">
        <div className="w-[50%]">
          <h1 className="dark-header m-0">
            Everyone knows there are over 8 million Akiya in Japan.
          </h1>
          <h1 className="light-header m-0">
            Here's the fuss-free way to actually own one.
          </h1>
        </div>
        <div className="flex justify-center items-center w-[50%]">
          <Link to="/services/how-it-works">
            <button className="py-[15px] px-[30px] bg-[#5ab963] rounded text-white text-[16px] font-[800]">
              Our Services
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default SingleBlog;