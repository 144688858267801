import React, { useState, useEffect, useRef } from "react";
import useWindowDimensions from "../useWindowDimensions";

const CardSlider = ({ data, Card, endValues }) => {
  const { width } = useWindowDimensions();
  const [start, setStart] = useState(0);
  const [visibleCards, setVisibleCards] = useState(3);
  const [showRight, setShowRight] = useState(true);
  const [showLeft, setShowLeft] = useState(false);
  const { sm, md, lg, xs } = endValues;
  const containerRef = useRef(null);

  const updateVisibleCards = () => {
    let newVisibleCards;
    if (width >= 991) {
      newVisibleCards = lg;
    } else if (width >= 640) {
      newVisibleCards = md;
    } else if (width >= 400 && sm) {
      newVisibleCards = sm;
    } else {
      newVisibleCards = xs;
    }
    setVisibleCards(newVisibleCards);
    return newVisibleCards;
  };

  const handleRight = () => {
    setShowLeft(true);
    if (start + visibleCards < data.length) {
      setStart(start + 1);
    }
    if (start + visibleCards + 1 >= data.length) {
      setShowRight(false);
    }
  };

  const handleLeft = () => {
    setShowRight(true);
    if (start > 0) {
      setStart(start - 1);
    }
    if (start - 1 === 0) {
      setShowLeft(false);
    }
  };

  useEffect(() => {
    const newVisibleCards = updateVisibleCards();
    setStart(0);
    setShowLeft(false);
    setShowRight(data.length > newVisibleCards);
  }, [width, data.length]);

  return (
    <div className="relative overflow-hidden" ref={containerRef} style={{ width: '105%'}}>
      <div 
        className="flex transition-transform duration-300 ease-in-out pb-4"
        style={{ transform: `translateX(-${start * (100 / visibleCards)}%)` }}
      >
        {data.map((item, index) => (
          <div 
            key={index} 
            style={{ width: `${100 / visibleCards}%` }} 
            className="flex-shrink-0 px-2"
          >
            <Card item={item} />
          </div>
        ))}
      </div>
      {showRight && (
        <button
          onClick={handleRight}
          className="next-btn absolute top-1/2 transform -translate-y-1/2 right-4 text-white bg-[#5ab963] opacity-75 rounded p-2 hover:opacity-100 hover:pl-4 hover:translate-x-2 transition-all text-2xl"
        >
          &#10095;
        </button>
      )}
      {showLeft && (
        <button
          onClick={handleLeft}
          className="prev-btn absolute top-1/2 transform -translate-y-1/2 left-4 text-white bg-[#5ab963] opacity-75 rounded p-2 hover:opacity-100 hover:pr-4 hover:-translate-x-2 transition-all text-2xl"
        >
          &#10094;
        </button>
      )}
    </div>
  );
};

export default CardSlider;