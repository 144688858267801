import React from "react";

const ImgContent = ({ data }) => {
  const { image, title, subtitle, content } = data;
  return (
    <div className="flex md:flex-row flex-col  w-full  ">
      <div className="lg:w-[40%] w-full  flex justify-center items-center ">
        <img src={image} className="w-[30rem] h-[20rem] object-cover  " />
      </div>

      <div className="lg:w-[50%] w-full flex flex-col justify-center  ">
        <h1 className="m-0 text-[24px] font-[800] leading-[36px] mt-3">
          {title}
        </h1>
        <h1 className="m-0 text-[#5ab963] text-[24px] font-[300] leading-[36px]">
          {subtitle}
        </h1>
        <p className="text-justify mt-4 text-[#949995] text-[16px] font-[400] leading-[24px]">
          {content}
        </p>
      </div>
    </div>
  );
};

export default ImgContent;
