const FirstSOVFullImage = "/images/Home/FirstSOVFullImage.jpeg"
const h2 = "/images/Home/h2.jpeg"
const h3 = "/images/Home/h3.jpeg"
const h4 = "/images/Home/h4.jpeg"
const h5 = "/images/Home/h5.jpeg"

export {FirstSOVFullImage}
export const noto = h2

export const sliderImages = [h3,h4,h5]

